.module-CookieBanner
{
    z-index: 2;
    background: #660000!important;
    position: absolute;
    width: 100%;
    bottom: 0;
    width: 100vw;
    left: 0;
    margin: 0;
    padding: 0;
    .btn
    {
        background-color: var(--theme_color);
        color: #fff!important;
    }
}
